import React, { useContext, useEffect, useRef, useState } from "react";
import Standby from "./Standby/Standby";
import StringHelper from "../../utilities/StringHelper";
import WebsocketHelper from "../../utilities/WebsocketHelper";

import styles from "./MainController.module.css";
import Game from "./Game/Game";
import End from "./End/End";
import DisconnectOverlay from "./DisconnectOverlay/DisconnectOverlay";
import { AppContext, AppContextType } from "../../context/AppContext";

type Props = {};

export default function MainController({}: Props) {
  const {
    setPlayerName,
    setScore,
    setButtonAState,
    setButtonBState,
    setButtonCState,
    setButtonDState,
    setLockOptions,
  }: AppContextType = useContext(AppContext);

  const [currentMainState, setCurrentMainState] = useState(
    StringHelper.WaitingState
  );

  useEffect(() => {
    //TODO: TODO: TODO: TODO: Set cached state

    //Listen for relevant websocket messages
    WebsocketHelper.pwWebsocketSDK.addMessageListener(
      StringHelper.SetPlayerNameMessage,
      handleSetPlayerName
    );

    WebsocketHelper.pwWebsocketSDK.addMessageListener(
      StringHelper.ShowStandbyMessage,
      handleShowStandby
    );

    WebsocketHelper.pwWebsocketSDK.addMessageListener(
      StringHelper.SetScoreMessage,
      handleSetScore
    );

    WebsocketHelper.pwWebsocketSDK.addMessageListener(
      StringHelper.ShowPreGameMessage,
      handleShowPreGame
    );

    WebsocketHelper.pwWebsocketSDK.addMessageListener(
      StringHelper.ShowIdleMessage,
      handleShowIdle
    );

    WebsocketHelper.pwWebsocketSDK.addMessageListener(
      StringHelper.ShowGameMessage,
      handleShowGame
    );

    WebsocketHelper.pwWebsocketSDK.addMessageListener(
      StringHelper.SetAnswerButtonsMessage,
      handleSetAnswerButtons
    );

    WebsocketHelper.pwWebsocketSDK.addMessageListener(
      StringHelper.ExitGameMessage,
      handleExitGame
    );

    return function cleanup() {
      WebsocketHelper.pwWebsocketSDK.removeMessageListener(
        StringHelper.SetPlayerNameMessage,
        handleSetPlayerName
      );

      WebsocketHelper.pwWebsocketSDK.removeMessageListener(
        StringHelper.ShowStandbyMessage,
        handleShowStandby
      );

      WebsocketHelper.pwWebsocketSDK.removeMessageListener(
        StringHelper.SetScoreMessage,
        handleSetScore
      );

      WebsocketHelper.pwWebsocketSDK.removeMessageListener(
        StringHelper.ShowPreGameMessage,
        handleShowPreGame
      );

      WebsocketHelper.pwWebsocketSDK.removeMessageListener(
        StringHelper.ShowIdleMessage,
        handleShowIdle
      );

      WebsocketHelper.pwWebsocketSDK.removeMessageListener(
        StringHelper.ShowGameMessage,
        handleShowGame
      );

      WebsocketHelper.pwWebsocketSDK.removeMessageListener(
        StringHelper.SetAnswerButtonsMessage,
        handleSetAnswerButtons
      );

      WebsocketHelper.pwWebsocketSDK.removeMessageListener(
        StringHelper.ExitGameMessage,
        handleExitGame
      );
    };
  }, []);

  //Websocket message handlers
  const handleSetPlayerName = (data) => {
    const playerName = JSON.parse(data).playerName;

    if (playerName) {
      setPlayerName(playerName);
    }
  };

  const handleShowStandby = () => {
    setCurrentMainState(StringHelper.WaitingState);
  };

  const handleSetScore = (data) => {
    const score = JSON.parse(data).score;

    if (score) {
      setScore(score);
    }
  };

  const handleShowPreGame = () => {
    setCurrentMainState(StringHelper.PreGameState);
  };

  const handleShowIdle = () => {
    setCurrentMainState(StringHelper.IdleState);
  };

  const handleShowGame = (data) => {
    if (data) {
      const dataP = JSON.parse(data);
      setButtonStates(dataP);
    }

    setCurrentMainState(StringHelper.GameState);
  };

  const handleSetAnswerButtons = (data) => {
    if (data) {
      const dataP = JSON.parse(data);
      setButtonStates(dataP);
    }
  };

  const handleExitGame = () => {
    setCurrentMainState(StringHelper.EndState);
  };

  //Helpers
  const setButtonStates = (data) => {
    setLockOptions(false);

    const aState = data.A;
    if (aState) {
      setButtonAState(aState);
    }

    const bState = data.B;
    if (bState) {
      setButtonBState(bState);
    }

    const cState = data.C;
    if (cState) {
      setButtonCState(cState);
    }

    const dState = data.D;
    if (dState) {
      setButtonDState(dState);
    }
  };

  const getCurrentView = () => {
    //Return the correct view depending on the stored state
    if (
      currentMainState === StringHelper.WaitingState ||
      currentMainState === StringHelper.PreGameState ||
      currentMainState === StringHelper.IdleState ||
      currentMainState === StringHelper.FullState
    ) {
      return <Standby state={currentMainState} />;
    } else if (currentMainState === StringHelper.GameState) {
      return <Game />;
    } else if (currentMainState === StringHelper.EndState) {
      return <End />;
    }
  };

  return <div className={styles.mainHolder}>{getCurrentView()}</div>;
}
