class StringHelper {
  static RegisterNameMessage = "RegisterName";

  static SendNameRequestMessage = "SendName";

  static StartTimerMessage = "StartTimer";

  static HelloMessage = "Hello";

  static ExitGameMessage = "ExitGame";

  static TimedOutMessage = "TimedOut";

  static RequestIdentityMessage = "RequestIdentity";

  static IdentifyMessage = "Identify";

  static SendAnswerMessage = "SendAnswer";

  static SetPlayerNameMessage = "SetPlayerName";

  static ShowStandbyMessage = "ShowStandby";

  static SetScoreMessage = "SetScore";

  static ShowPreGameMessage = "ShowPreGame";

  static ShowIdleMessage = "ShowIdle";

  static ShowGameMessage = "ShowGame";

  static SetAnswerButtonsMessage = "SetAnswerButtons";

  //App states
  static IntakeState = "intake";
  static WaitingState = "waiting";
  static PreGameState = "pre-game";
  static FullState = "full";
  static IdleState = "idle";
  static GameState = "game";
  static EndState = "end";

  //Button states
  static EnabledButtonState = "Enabled";
  static DisabledButtonState = "Disabled";
  static CorrectButtonState = "Correct";
  static IncorrectButtonState = "Incorrect";
  static HiddenButtonState = "Hidden";
  static SelectedButtonState = "Selected";

  //Reconnect LocalStorage properties
  static LastConnectTimestamp = "LastConnectTimestamp";
  static AppState = "AppState";
  static IntakeName = "IntakeName";
  static IntakeEmail = "IntakeEmail";
  static GameID = "GameID";
  static IdentifyTimestamp = "IdentifyTimestamp";

  static AppStateGame = "app-game";
  static AppStateEnd = "app-end";

  static waitingSubtext = "Waiting for others to join the game";

  static beginSubtext = "Alright, let's begin!";
}

export default StringHelper;