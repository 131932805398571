import StringHelper from "./StringHelper";
import PWWebsocketSDK from "../sdk/pw__websocket_sdk";

class WebsocketHelper {
  static serverBaseURL =
    window.location.hostname.includes("pwdev") ||
    process.env.REACT_APP_API_STAGE === "dev"
      ? "wss://engine.showboat.live:3415"
      : "wss://engine.showboat.live:3414";

  static pwWebsocketSDK = new PWWebsocketSDK();

  static nameRequestListener;
  static requestIdentityListener;
  static timedOutListener;

  static identifyTimestamp;

  static currentState = "none";

  static needStoreIdentifyTimestamp = true;

  static serverID;

  static nameToSend;
  static emailToSend;
  static gameID;

  static async connectToWebsocket(name, email, gameID) {
    return new Promise(async (resolve) => {
      try {
        const serverID = this.pwWebsocketSDK.getQueryParam("s");

        this.serverID = serverID;

        this.nameToSend = name;
        this.emailToSend = email;
        this.gameID = gameID;

        const res = await this.pwWebsocketSDK.connectToGameServer(
          this.serverBaseURL,
          serverID,
          ""
        );

        if (res) {
          //Store last connection timestamp
          this.pwWebsocketSDK.setInLocalStorage(
            `${StringHelper.LastConnectTimestamp}_${serverID}`,
            JSON.stringify(new Date().getTime())
          );

          //Set up listeners
          this.requestIdentityListener = this.handleRequestIdentity;
          this.requestIdentityListener =
            this.requestIdentityListener.bind(this);
          this.pwWebsocketSDK.addMessageListener(
            StringHelper.RequestIdentityMessage,
            this.requestIdentityListener
          );

          this.timedOutListener = this.handleTimedOut;
          this.timedOutListener = this.timedOutListener.bind(this);
          this.pwWebsocketSDK.addMessageListener(
            StringHelper.TimedOutMessage,
            this.timedOutListener
          );

          resolve(true);
        } else {
          resolve(false);
        }
      } catch (error) {
        console.log("Error connecting to websocket:", error);
        resolve(false);
      }
    });
  }

  //Helpers
  static sendAnswer(answerIndex: number) {
    this.pwWebsocketSDK.sendWebsocketMessage(StringHelper.SendAnswerMessage, {
      answerIndex,
    });
  }

  //Private helpers
  static sendNameRequestResponse(name) {
    this.pwWebsocketSDK.sendWebsocketMessage(StringHelper.RegisterNameMessage, {
      name,
    });
  }

  static handleExitGame() {
    //Clear cache
    this.clearCache();

    this.pwWebsocketSDK.permanentlyDisconnect();

    //Remove send name listener
    this.pwWebsocketSDK.removeMessageListener(
      StringHelper.SendNameRequestMessage,
      this.nameRequestListener
    );

    //Remove RequestIdentity listener
    this.pwWebsocketSDK.removeMessageListener(
      StringHelper.RequestIdentityMessage,
      this.requestIdentityListener
    );

    //Remove TimedOut listener
    this.pwWebsocketSDK.removeMessageListener(
      StringHelper.TimedOutMessage,
      this.timedOutListener
    );

    //Allow new timestamp to be stored
    this.needStoreIdentifyTimestamp = true;

    //Reset state
    this.currentState = "none";
  }

  static handleRequestIdentity() {
    //Check for cached name, email, gameID
    const cachedName = this.pwWebsocketSDK.getFromLocalStorage(
      `${StringHelper.IntakeName}_${this.serverID}`
    );

    const cachedEmail = this.pwWebsocketSDK.getFromLocalStorage(
      `${StringHelper.IntakeEmail}_${this.serverID}`
    );

    const cachedGameID = this.pwWebsocketSDK.getFromLocalStorage(
      `${StringHelper.GameID}_${this.serverID}`
    );

    //Respond with identity
    this.pwWebsocketSDK.sendWebsocketMessage(StringHelper.IdentifyMessage, {
      name: cachedName ? cachedName : this.nameToSend,
      email: cachedEmail ? cachedEmail : this.emailToSend,
      gameID: cachedGameID ? cachedGameID : this.gameID,
    });
  }

  static handleTimedOut() {
    //Clear cache
    this.clearCache();

    //Refresh page
    window.location.reload();
  }

  static handleAppLoad() {
    //TODO: TODO: TODO: Check state for this code

    //Check state to determine if we need to connect, or go to home screen
    const connectEndOrNone = this.handleCheckState();

    if (connectEndOrNone === "connect") {
      //Make sure other cached properties are present
      if (!this.checkForCachedProperties()) {
        this.clearCache();
        return "none";
      }

      //Set nameToSend
      this.nameToSend = this.pwWebsocketSDK.getFromLocalStorage(
        `${StringHelper.IntakeName}_${this.serverID}`
      );

      //Set emailToSend
      this.emailToSend = this.pwWebsocketSDK.getFromLocalStorage(
        `${StringHelper.IntakeEmail}_${this.emailToSend}`
      );

      return "connect";
    } else if (connectEndOrNone === "end") {
      return "end";
    } else {
      //Go to intake
      return "none";
    }
  }

  static clearCache() {
    //Clear:
    //AppState
    //Tokens
    //TimerStartTimestamp
    //LastConnectTimestamp
    //IntakeName
    //IdentifyTimestamp

    const clearArray = [
      `${StringHelper.AppState}`,
      `${StringHelper.LastConnectTimestamp}`,
      `${StringHelper.IntakeName}`,
      `${StringHelper.IntakeEmail}`,
      `${StringHelper.IdentifyTimestamp}`,
      `${StringHelper.GameID}`,
    ];

    for (let i = 0; i < clearArray.length; i++) {
      localStorage.removeItem(`${clearArray[i]}_${this.serverID}`);
    }
  }

  static checkForCachedProperties() {
    //Check for:
    //IntakeName
    //IdentifyTimestamp

    const checkArray = [
      `${StringHelper.IntakeName}`,
      `${StringHelper.IntakeEmail}`,
      `${StringHelper.IdentifyTimestamp}`,
    ];

    for (let i = 0; i < checkArray.length; i++) {
      if (localStorage.getItem(`${checkArray[i]}_${this.serverID}`) == null) {
        return false;
      }
    }

    return true;
  }

  static handleCheckState() {
    const serverID = this.pwWebsocketSDK.getQueryParam("s");

    this.serverID = serverID;

    //Returns "connect", "end" or "none", depending on state and timestamp

    //Get AppState from localStorage
    let appState = localStorage.getItem(
      `${StringHelper.AppState}_${serverID}_${this.pwWebsocketSDK.getQueryParam(
        "g"
      )}`
    );

    if (appState) {
      if (appState === StringHelper.AppStateGame) {
        //Get LastConnection timestamp
        let timestamp = this.pwWebsocketSDK.getFromLocalStorage(
          `${StringHelper.LastConnectTimestamp}_${serverID}`
        );

        //Make sure last connection was within 1 hour
        if (timestamp && new Date().getTime() - parseInt(timestamp) < 3600000)
          return "connect";
      } else if (appState === StringHelper.AppStateEnd) {
        return "end";
      }
    }
    return "none";
  }
}

export default WebsocketHelper;
