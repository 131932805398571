import React, { useContext, useEffect } from "react";
import { AppContext, AppContextType } from "../../../context/AppContext";

import Option from "./Option/Option";

import styles from "./Game.module.css";
import WebsocketHelper from "../../../utilities/WebsocketHelper";
import StringHelper from "../../../utilities/StringHelper";

type Props = {};

export default function Game({}: Props) {
  const {
    playerName,
    score,
    buttonAState,
    buttonBState,
    buttonCState,
    buttonDState,
    setLockOptions,
  }: AppContextType = useContext(AppContext);

  useEffect(() => {
    //Set state in local storage
    WebsocketHelper.pwWebsocketSDK.setInLocalStorage(
      `${StringHelper.AppState}_${WebsocketHelper.pwWebsocketSDK.getQueryParam(
        "s"
      )}_${WebsocketHelper.pwWebsocketSDK.getQueryParam("g")}`,
      StringHelper.AppStateGame
    );
  }, []);

  const handleOptionSelect = (answerIndex: number) => {
    console.log("Answer index clicked:", answerIndex);
    setLockOptions(true);

    WebsocketHelper.sendAnswer(answerIndex);
  };

  return (
    <div className={styles.gameHolder}>
      <div className={styles.gameInfoHolder}>
        <p className={styles.playerName}>{playerName}</p>

        <p className={styles.score}>{score}</p>
      </div>

      <div className={styles.optionsHolder}>
        <Option
          optionText={"A"}
          state={buttonAState}
          onClick={handleOptionSelect}
          answerIndex={0}
        />
        <Option
          optionText={"B"}
          state={buttonBState}
          onClick={handleOptionSelect}
          answerIndex={1}
        />
        <Option
          optionText={"C"}
          state={buttonCState}
          onClick={handleOptionSelect}
          answerIndex={2}
        />
        <Option
          optionText={"D"}
          state={buttonDState}
          onClick={handleOptionSelect}
          answerIndex={3}
        />
      </div>
    </div>
  );
}
