import React from 'react'

import styles from "./Header.module.css";

export default function Header() {
  return (
    <div className={styles.header}>
      <img className={styles.headerImage} alt="Header logo" src="/assets/images/logo.svg" />
    </div>
  )
}
