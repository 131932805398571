import React, { useContext, useState } from "react";
import StringHelper from "../utilities/StringHelper";

export interface AppContextType {
  rank: number,
  setRank: (rank: number) => void,
  score: number,
  setScore: (score: number) => void,
  nameContext: string,
  setNameContext: (nameContext: string) => void,
  emailContext: string,
  setEmailContext: (emailContext: string) => void,
  playerName: string,
  setPlayerName: (playerName: string) => void,
  buttonAState: string,
  setButtonAState: (buttonAState: string) => void,
  buttonBState: string,
  setButtonBState: (buttonBState: string) => void,
  buttonCState: string,
  setButtonCState: (buttonCState: string) => void,
  buttonDState: string,
  setButtonDState: (buttonDState: string) => void,
  forceEnd: boolean;
  setForceEnd: (forceEnd : boolean) => void,
  lockOptions: boolean,
  setLockOptions: (lockOptions: boolean) => void
}

export const AppContext = React.createContext({} as AppContextType);

export default function AppContextProvider(props) {
  const [rank, setRank] = useState(-1);

  const [score, setScore] = useState(3741);

  const [nameContext, setNameContext] = useState("Test Name");
  const [emailContext, setEmailContext] = useState("");

  const [playerName, setPlayerName] = useState("Player 1");

  const [buttonAState, setButtonAState] = useState(StringHelper.EnabledButtonState);
  const [buttonBState, setButtonBState] = useState(
    StringHelper.EnabledButtonState
  );
  const [buttonCState, setButtonCState] = useState(
    StringHelper.EnabledButtonState
  );
  const [buttonDState, setButtonDState] = useState(
    StringHelper.EnabledButtonState
  );

  const [forceEnd, setForceEnd] = useState(false);

  const [lockOptions, setLockOptions] = useState(false);

  const AppContextValue : AppContextType = {
    score,
    setScore,
    rank,
    setRank,
    nameContext,
    setNameContext,
    playerName,
    setPlayerName,
    buttonAState,
    setButtonAState,
    buttonBState,
    setButtonBState,
    buttonCState,
    setButtonCState,
    buttonDState,
    setButtonDState,
    emailContext,
    setEmailContext,
    forceEnd,
    setForceEnd,
    lockOptions,
    setLockOptions
  };

  return (
    <AppContext.Provider value={AppContextValue}>
      {props.children}
    </AppContext.Provider>
  );
}
