import React, { useContext } from "react";

import styles from "./Option.module.css";
import StringHelper from "../../../../utilities/StringHelper";
import { AppContext, AppContextType } from "../../../../context/AppContext";

type Props = {
  optionText: string;
  onClick: (answerIndex: number) => void;
  state: string;
  answerIndex: number
};

export default function Option(props: Props) {
  const {
    setButtonAState,
    setButtonBState,
    setButtonCState,
    setButtonDState,
    lockOptions
  } : AppContextType = useContext(AppContext);
  

  const handleButtonPress = () => {
    if (lockOptions) return;
    
    if (props.state === StringHelper.EnabledButtonState) {

      switch (props.answerIndex) {
        case 0:
          setButtonAState(StringHelper.SelectedButtonState);
          break;
        case 1:
          setButtonBState(StringHelper.SelectedButtonState);
          break;
        case 2:
          setButtonCState(StringHelper.SelectedButtonState);
          break;
        case 3:
          setButtonDState(StringHelper.SelectedButtonState);
          break;
      }
      
      props.onClick(props.answerIndex);
    }
  }
  
  const getClass = (state) => {
    switch (state) {
      case StringHelper.EnabledButtonState:
        return `${styles.option}`;
      case StringHelper.HiddenButtonState:
        return `${styles.option} ${styles.optionHidden}`;
      case StringHelper.SelectedButtonState:
        return `${styles.option} ${styles.optionSelected}`;
      case StringHelper.DisabledButtonState:
        return `${styles.option} ${styles.optionDisabled}`;
      case StringHelper.CorrectButtonState:
        return `${styles.option} ${styles.optionCorrect}`;
    }
  };

  return (
    <button
      className={getClass(props.state)}
      onClick={handleButtonPress}
    >
      {props.optionText}
    </button>
  );
}
