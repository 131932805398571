import React, { useContext, useEffect } from 'react';

import styles from "./End.module.css";
import WebsocketHelper from '../../../utilities/WebsocketHelper';
import StringHelper from '../../../utilities/StringHelper';
import { AppContext, AppContextType } from '../../../context/AppContext';

type Props = {}

export default function End({}: Props) {
  const { playerName, score } : AppContextType = useContext(AppContext);
  
  useEffect(() => {
    //Set state in local storage
    WebsocketHelper.pwWebsocketSDK.setInLocalStorage(
      `${StringHelper.AppState}_${WebsocketHelper.pwWebsocketSDK.getQueryParam(
        "s"
      )}_${WebsocketHelper.pwWebsocketSDK.getQueryParam("g")}`,
      StringHelper.AppStateEnd
    );
  }, []);
  
  return (
    <div className={styles.endHolder}>
      <div className={styles.gameInfoHolder}>
        <p className={styles.playerName}>{playerName}</p>

        <p className={styles.score}>{score}</p>
      </div>

      <img
        alt="boston-scientific-logo"
        src="/assets/images/game-logo.svg"
        className={styles.logo}
      />

      <p className={styles.endText}>Thanks for playing!</p>
    </div>
  );
}