import React, { useContext, useRef, useState } from 'react'
import { AppContext, AppContextType } from '../../context/AppContext';
import StringHelper from '../../utilities/StringHelper';
import WebsocketHelper from '../../utilities/WebsocketHelper';
import styles from "./Intake.module.css";

type Props = {
  handleIntakeSubmit: (name: string, email: string) => void,
  disableJoin: boolean
}

export default function Intake(props: Props) {
  const { setNameContext, setEmailContext } = useContext(AppContext);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const nameRef = useRef("");
  const emailRef = useRef("");

  const handleNameChange = (e) => {
    setName(e.target.value);

    nameRef.current = e.target.value;
  };

  const handleEmailChange = (e) => {
    //TODO: Email validation
    setEmail(e.target.value);

    emailRef.current = e.target.value;
  }

  const handleJoinClick = () => {
    if (!name.trim().length) return;
    if (!email.trim().length) return;

    //TODO: Validate email

    props.handleIntakeSubmit(nameRef.current, emailRef.current);

    //Store name in cache
    WebsocketHelper.pwWebsocketSDK.setInLocalStorage(
      `${StringHelper.IntakeName}_${WebsocketHelper.serverID}`,
      nameRef.current
    );

    //Store email in cache
    WebsocketHelper.pwWebsocketSDK.setInLocalStorage(
      `${StringHelper.IntakeEmail}_${WebsocketHelper.serverID}`,
      emailRef.current
    );

    setNameContext(nameRef.current);
    setEmailContext(emailRef.current);
  };

  return (
    <div className={styles.intakeHolder}>
      <h1 className={styles.intakeHeader}>
        Enter your details to join the game
      </h1>

      <input
        type="text"
        value={name}
        onChange={handleNameChange}
        className={styles.intakeInput}
        maxLength={32}
        placeholder="Name"
      />

      <input
        type="text"
        value={email}
        onChange={handleEmailChange}
        className={styles.intakeInput}
        maxLength={32}
        placeholder="Email"
      />

      <button
        disabled={props.disableJoin}
        className={styles.joinButton}
        onClick={handleJoinClick}
      >
        JOIN
      </button>
    </div>
  );
}