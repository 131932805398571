class Delegate {
    constructor() {
        this.add = this.add.bind(this);
        this.remove = this.remove.bind(this);
        this.raise = this.raise.bind(this);
        this.clear = this.clear.bind(this);
    }

    listeners = [];

    //Adds a delegate handler function
    add(listener) {
        //Avoid duplicates
        for (let i = 0; i < this.listeners.length; ++i) {
            if (this.listeners[i] === listener) return;
        }
        this.listeners.push(listener);
    }

    //Removes a delegate handler function
    remove(listener) {
        this.listeners = this.listeners.filter((x) => x !== listener);
    }

    //Raise a delegate event with arguments
    raise(...args) {
        this.listeners.slice(0).forEach((x) => x(...args));
    }

    clear() {
        this.listeners = [];
    }

    //Gets the count of listeners
    get count() {
        if (this.listeners) {
            return this.listeners.length;
        }

        return 0;
    }
}

class PWWebsocketSDK {
    constructor() {
        //Bind "this" context to all methods
        this.addMessageListener = this.addMessageListener.bind(this);
        this.removeMessageListener = this.removeMessageListener.bind(this);
        this.raiseMessageListener = this.raiseMessageListener.bind(this);
        this.handleWebsocketMessage = this.handleWebsocketMessage.bind(this);
        this.connectToGameServer = this.connectToGameServer.bind(this);
        this.handleWebsocketClose = this.handleWebsocketClose.bind(this);
        this.handleWebsocketError = this.handleWebsocketError.bind(this);
        this.handleWebsocketOpen = this.handleWebsocketOpen.bind(this);
        this.handleWebsocketMessage = this.handleWebsocketMessage.bind(this);
        this.sendWebsocketMessage = this.sendWebsocketMessage.bind(this);
        this.disconnect = this.disconnect.bind(this);
        this.handleBan = this.handleBan.bind(this);
        this.handleDisconnectRequest = this.handleDisconnectRequest.bind(this);
        this.handleConnectionResult = this.handleConnectionResult.bind(this);
        this.handleGameServerDisconnection =
            this.handleGameServerDisconnection.bind(this);
        this.checkForBan = this.checkForBan.bind(this);
        this.sendPermanentDisconnect = this.sendPermanentDisconnect.bind(this);
        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
        this.startPingInterval = this.startPingInterval.bind(this);
        this.startPongMonitorInterval =
            this.startPongMonitorInterval.bind(this);
        this.sendPing = this.sendPing.bind(this);
        this.handlePong = this.handlePong.bind(this);
        this.checkSessionIDOnDisconnect =
            this.checkSessionIDOnDisconnect.bind(this);
        this.handleSetSessionIDOnPageLoad =
            this.handleSetSessionIDOnPageLoad.bind(this);

        //Set up page load listener
        window.addEventListener("load", this.handleSetSessionIDOnPageLoad);
    }

    //Type Map<string, Delegate>
    messageListeners = new Map();

    BAN_MESSAGE = "Ban";
    DISCONNECT_REQUEST_MESSAGE = "DisconnectRequest";
    PERMANENT_DISCONNECT_MESSAGE = "PlayerPermanentDisconnect";

    HEARTBEAT_ACTION = "Heartbeat";
    PING_ACTION = "Ping";

    isConnecting = false;

    playerID;

    sessionID;

    reconnectInterval = 1000;

    reconnectTimeout;

    onlyAllowReconnectIfCurrentSessionID = true;

    isInitted = false;

    isDisconnectedIntentionally = false;

    refreshOnFocus = false;

    websocket;

    serverID;

    pingIntervalMS = 2000;
    pongIntervalMS = 1000;
    pongAllowedMSDifference = 5000;
    pingInterval;
    pongMonitorInterval;
    lastPong;

    //Delegate for all messages
    onMessage = new Delegate(); //<{ message: string, messageData: string}>

    onDisconnect = new Delegate(); //null

    onConnect = new Delegate(); //null

    onDisconnectRequest = new Delegate(); //null

    onGoodbye = new Delegate(); //null

    onBan = new Delegate(); //null

    onConnectFail = new Delegate(); //null

    pendingPings = new Map();

    addMessageListener(messageName, listener) {
        let delegate = this.messageListeners.get(messageName);
        if (!delegate) {
            delegate = new Delegate();
            this.messageListeners.set(messageName, delegate);
        }
        delegate.add(listener);
    }

    removeMessageListener(messageName, listener) {
        let delegate = this.messageListeners.get(messageName);
        if (delegate) {
            delegate.remove(listener);

            if (delegate.count == 0) {
                this.messageListeners.delete(messageName);
            }
        }
    }

    raiseMessageListener(messageName, messageData) {
        //Raise specific listeners
        let delegate = this.messageListeners.get(messageName);

        if (delegate) {
            delegate.raise(messageData);
        }
    }

    handleWebsocketMessage(wsMsg) {
        let dataParsed = JSON.parse(wsMsg.data);
        let action = dataParsed.action;
        let msgData = dataParsed.data;

        //Check if this is an AppMessage
        if (action == "AppMessage") {
            const message = msgData.message;
            const messageData = msgData.messageData;

            console.log("App message received:", message, messageData);

            //Raise generic message listener
            this.onMessage.raise({ message, messageData });

            this.raiseMessageListener(message, messageData);
        } else {
            if (action !== "Pong") {
                console.log("Server message received:", msgData);
            }

            //Handle server actions
            switch (action) {
                case "Goodbye":
                    this.handleDisconnectRequest();
                    break;
                case "Ban":
                    this.handleBan();
                    break;
                case "ConnectionResult":
                    this.handleConnectionResult(msgData);
                    break;
                case "ServerLost":
                    this.handleGameServerDisconnection();
                    break;
                case "Pong":
                    this.handlePong();
                    break;
                default:
                    console.log("Unknown action received:", action);
                    break;
            }
        }
    }

    connectToGameServer(url, serverID, stringArguments, autoReconnect = true) {
        return new Promise((resolve) => {
            if (this.checkForBan()) {
                resolve(false);
                return;
            }

            this.serverID = serverID;

            //Handle playerID
            let playerID = this.getFromLocalStorage(`PlayerID_${serverID}`);
            if (playerID) {
                this.playerID = playerID;
            } else {
                this.playerID = this.uuidv4();
                playerID = this.playerID;
                this.setInLocalStorage(`PlayerID_${serverID}`, this.playerID);
            }

            //Only connect if we do not have a websocket open or connecting
            if (
                this.websocket &&
                (this.websocket.readyState === 1 ||
                    this.websocket.readyState === 0)
            ) {
                resolve(false);
                return;
            }

            console.log("Connecting to websocket server");

            //Check if a connection attempt is in progress
            if (this.isConnecting) return;

            this.isConnecting = true;

            if (this.websocket) {
                //Remove old listeners
                this.websocket.onclose = null;
                this.websocket.onopen = null;
                this.websocket.onerror = null;
                this.websocket.onmessage = null;
            }

            this.websocket = new WebSocket(
                `${url}?playerID=${playerID}&serverID=${serverID}&isServer=false&arguments=${stringArguments}`
            );

            this.websocket.onopen = () =>
                this.handleWebsocketOpen(
                    resolve,
                    url,
                    serverID,
                    playerID,
                    stringArguments
                );

            this.websocket.onerror = () => this.handleWebsocketError(resolve);

            this.websocket.onclose = () =>
                this.handleWebsocketClose(
                    resolve,
                    url,
                    serverID,
                    playerID,
                    stringArguments
                );
        });
    }

    handleWebsocketOpen(resolve, url, serverID, playerID, stringArguments) {
        console.log("Connected to websocket");

        this.isConnecting = false;

        this.playerID = playerID;

        this.websocket.onmessage = this.handleWebsocketMessage;

        //Start ping interval
        this.startPingInterval();

        //Start pong monitor interval
        this.startPongMonitorInterval();

        //Initiate lastPong variable
        this.lastPong = new Date().getTime();

        if (!this.isInitted) {
            this.isInitted = true;

            //Set up visibility listener to attempt reconnect
            document.onvisibilitychange = () =>
                this.handleVisibilityChange(url, serverID, stringArguments);
        }

        resolve(true);
    }

    handleWebsocketClose(resolve, url, serverID, playerID, stringArguments) {
        console.log("Disconnected from websocket server");

        this.checkSessionIDOnDisconnect();

        this.isConnecting = false;

        this.onDisconnect.raise();

        if (this.pingTimeout) {
            clearTimeout(this.pingTimeout);
        }

        if (this.heartbeatInterval) {
            clearInterval(this.heartbeatInterval);
        }

        if (this.pingInterval) {
            clearInterval(this.pingInterval);
        }

        if (this.pingTimeout) {
            clearTimeout(this.pingTimeout);
        }

        if (!this.isDisconnectedIntentionally && !this.refreshOnFocus) {
            //Start auto-reconnect sequence
            if (this.reconnectTimeout) {
                clearTimeout(this.reconnectTimeout);
            }

            this.reconnectTimeout = setTimeout(
                () => this.connectToGameServer(url, serverID, stringArguments),
                this.reconnectInterval
            );
            this.reconnectInterval = this.reconnectInterval * 2;
        }

        resolve(false);
    }

    handleWebsocketError(resolve) {
        console.log("Error connecting to websocket");

        this.isConnecting = false;

        resolve(false);
    }

    sendWebsocketMessage(message, messageData, action = "PlayerMessage") {
        let messageDataToSend = messageData;

        //Only stringify messageData if it is not a string
        if (typeof messageData !== "string") {
            messageDataToSend = JSON.stringify(messageData);
        }

        try {
            if (this.websocket) {
                this.websocket.send(
                    JSON.stringify({
                        action,
                        body: {
                            data: {
                                message,
                                messageData: messageDataToSend,
                            },
                        },
                    })
                );
            }
        } catch (error) {
            console.log("Error sending websocket message:", error);
        }
    }

    disconnect() {
        this.websocket.close();
    }

    getQueryParam(paramName) {
        paramName = paramName.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        let regex = new RegExp("[\\?&]" + paramName + "=([^&#]*)");
        let results = regex.exec(window.location.search);
        return results !== null
            ? decodeURIComponent(results[1].replace(/\+/g, " "))
            : null;
    }

    //Private helpers

    permanentlyDisconnect() {
        this.isDisconnectedIntentionally = true;

        this.websocket.close();

        this.websocket = null;
    }

    handleBan() {
        this.isDisconnectedIntentionally = true;

        //Set ban in local storage
        localStorage.setItem("IS_BANNED", "true");

        window.location.reload();
    }

    handleDisconnectRequest() {
        console.log("Disconnect request received");

        this.isDisconnectedIntentionally = true;

        //Disconnect from server
        this.disconnect();
    }

    handleConnectionResult(result) {
        if (result.connected === true) {
            //Reset reconnect interval
            this.reconnectInterval = 1000;
        }

        this.onConnect.raise(result);
    }

    handleGameServerDisconnection() {
        console.log("Game server disconnected");
    }

    checkForBan() {
        return localStorage.getItem("IS_BANNED") == "true";
    }

    sendPermanentDisconnect() {
        this.websocket.send(
            JSON.stringify({
                action: this.PERMANENT_DISCONNECT_MESSAGE,
                body: {
                    data: null,
                },
            })
        );
    }

    getFromLocalStorage(key) {
        return localStorage.getItem(key);
    }

    setInLocalStorage(key, value) {
        localStorage.setItem(key, value);
    }

    uuidv4() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                var r = (Math.random() * 16) | 0,
                    v = c == "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            }
        );
    }

    /* sendHeartbeat() {
        this.websocket.send(
            JSON.stringify({
                action: this.HEARTBEAT_ACTION,
                body: null,
            })
        );
    } */

    /* startHeartbeatInterval() {
        this.heartbeatInterval = setInterval(
            this.sendHeartbeat,
            this.heartbeatIntervalMS
        );
    } */

    sendPing() {
        this.websocket.send(
            JSON.stringify({
                action: this.PING_ACTION,
                body: {
                    data: null,
                },
            })
        );
    }

    startPingInterval() {
        if (this.pingInterval) {
            clearInterval(this.pingInterval);
        }

        this.pingInterval = setInterval(() => {
            if (this.websocket && this.websocket.readyState === 1)
                this.sendPing();
        }, this.pingIntervalMS);
    }

    startPongMonitorInterval() {
        if (this.pongMonitorInterval) {
            clearInterval(this.pongMonitorInterval);
        }

        this.pongMonitorInterval = setInterval(() => {
            if (this.websocket && this.websocket.readyState === 1) {
                //Check if time since last pong > pongAllowedMSDifference
                if (
                    new Date().getTime() - this.lastPong >
                    this.pongAllowedMSDifference
                ) {
                    this.disconnect();
                }
            }
        }, this.pongIntervalMS);
    }

    handlePong() {
        this.lastPong = new Date().getTime();
    }

    checkSessionIDOnDisconnect() {
        //Check if localStorage ID isn't equal to ours
        let sessionIDCache = this.getFromLocalStorage(
            `SessionID_${this.serverID}`
        );

        if (sessionIDCache && this.sessionID !== sessionIDCache) {
            //Mark to refresh on tab refocus
            this.refreshOnFocus = true;
        }
    }

    handleSetSessionIDOnPageLoad() {
        let sessionID = this.uuidv4();

        this.sessionID = sessionID;

        this.setInLocalStorage(
            `SessionID_${this.getQueryParam("s")}`,
            sessionID
        );
    }

    handleVisibilityChange(url, serverID, stringArguments) {
        //Handle sessionID check
        if (this.onlyAllowReconnectIfCurrentSessionID && this.refreshOnFocus) {
            window.location.reload();

            return;
        }

        if (document.visibilityState === "visible") {
            //Attempt connection if we are disconnected
            if (
                this.websocket &&
                this.websocket.readyState !== 0 &&
                this.websocket.readyState !== 1
            ) {
                this.connectToGameServer(url, serverID, stringArguments);
            }
        }
    }
}

export default PWWebsocketSDK;
