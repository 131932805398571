import React from 'react'
import styles from "./LandscapeOverlay.module.css";

export default function LandscapeOverlay() {
  return (
    <div className={styles.landscapeOverlay}>
      <p>Landscape mode is not supported</p>
    </div>
  );
}
