import React, { useContext } from 'react';
import StringHelper from "../../../utilities/StringHelper";
import styles from "./Standby.module.css";
import { AppContext, AppContextType } from '../../../context/AppContext';

type Props = {
  state: string
}

export default function Standby(props: Props) {
  const { playerName } : AppContextType = useContext(AppContext);

  const getGameSubtext = () => {
    let gameSubtext = "";

    switch (props.state) {
      case StringHelper.WaitingState:
        gameSubtext = StringHelper.waitingSubtext;
        break;
      case StringHelper.PreGameState:
        gameSubtext = StringHelper.beginSubtext;
        break;
    }

    return gameSubtext;
  }

  return (
    <div className={styles.standbyHolder}>
      <img
        alt="boston-scientific-logo"
        src="/assets/images/game-logo.svg"
        className={styles.logo}
      />

      {props.state !== StringHelper.IdleState && (
        <>
          <p className={styles.playerName}>{playerName}</p>

          <p className={styles.gameSubtext}>{getGameSubtext()}</p>
        </>
      )}
    </div>
  );
}