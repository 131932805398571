import React from "react";
import { useEffect } from "react";

import styles from "./DisconnectOverlay.module.css";
import Spinner from "./Spinner/Spinner";

interface Props {
  isReconnect: boolean,
  handleClose: () => void
}

export default function DisconnectOverlay(props: Props) {
  return (
    <div className={styles.disconnectOverlay}>
      <div className={styles.disconnectText}>
        <Spinner />
        <p className={styles.standby}>Please standby.</p>
        <p className={styles.attemptingToConnect}>
          Attempting to {props.isReconnect ? "reconnect." : "connect."}
        </p>
      </div>
    </div>
  );
}
