import React, { useContext, useEffect, useRef, useState } from "react";
import "./App.css";
import { isMobile } from "react-device-detect";
import LandscapeOverlay from "./components/LandscapeOverlay/LandscapeOverlay";
import Header from "./components/Header/Header";
import WebsocketHelper from "./utilities/WebsocketHelper";
import StringHelper from "./utilities/StringHelper";
import Intake from "./components/Intake/Intake";
import MainController from "./components/MainController/MainController";
import { AppContext, AppContextType } from "./context/AppContext";
import DisconnectOverlay from "./components/MainController/DisconnectOverlay/DisconnectOverlay";

function App() {
  const { setForceEnd } : AppContextType = useContext(AppContext);

  const [intakeCompleted, setIntakeCompleted] = useState(false);

  const [isDisconnected, setIsDisconnected] = useState(false);
  const [isReconnect, setIsReconnect] = useState(false);

  const [disableJoin, setDisableJoin] = useState(false);

  const nameRef = useRef("");
  const emailRef = useRef("");
  const isDisconnectedRef = useRef(false);

  useEffect(() => {
    //Listen for disconnects
    WebsocketHelper.pwWebsocketSDK.onDisconnect.add(handleServerDisconnect);
    WebsocketHelper.pwWebsocketSDK.onConnect.add(handleServerConnect);

    //Check if we need to connect right away
    const state = WebsocketHelper.handleAppLoad();

    console.log("State:", state);

    if (state === "connect") {
      const serverID = WebsocketHelper.pwWebsocketSDK.getQueryParam("s");

      handleIntakeSubmit(
        WebsocketHelper.pwWebsocketSDK.getFromLocalStorage(
          `${StringHelper.IntakeName}_${serverID}`
        ),
        WebsocketHelper.pwWebsocketSDK.getFromLocalStorage(
          `${StringHelper.IntakeEmail}_${serverID}`
        )
      );

      //Show main app
      setIntakeCompleted(true);
    } else if (state === "end") {
      //Render end screen
      setForceEnd(true);

      setIntakeCompleted(true);
    }

    return function cleanup() {
      //Listen for disconnects
      WebsocketHelper.pwWebsocketSDK.onDisconnect.remove(handleServerDisconnect);
      WebsocketHelper.pwWebsocketSDK.onConnect.remove(handleServerConnect);
    }
  }, []);

  const handleIntakeSubmit = async (name, email) => {
    console.log("NAME:", name);
    console.log("EMAIL:", email);
    
    if (!name.length || !email.length) return;
    //TODO: Validate email here?

    setDisableJoin(true);

    nameRef.current = name;
    emailRef.current = email;

    const connectResponse = await WebsocketHelper.connectToWebsocket(
      name,
      email,
      WebsocketHelper.pwWebsocketSDK.getQueryParam("g")
    );

    console.log("Connect response:", connectResponse);

    setDisableJoin(false);

    if (connectResponse) {
      //TODO: Might need to change where this set is placed?
      setIsReconnect(true);
      
      setIntakeCompleted(true);
    }
  };

  const handleServerDisconnect = () => {
    isDisconnectedRef.current = true;
    setIsDisconnected(true);
  }
  
  const handleServerConnect = (data) => {
    if (data.connected) {
      isDisconnectedRef.current = false;
      setIsDisconnected(false);
    }
  }

  const handleDisconnectOverlayClose = () => {
    setIsDisconnected(false);
    isDisconnectedRef.current = false;
    
  }

  return (
    <div className="App">
      <div className="bkg">
        <img
          alt="background-image"
          src="/assets/images/kidney.svg"
          className="bkgImage"
        />
      </div>
      {isMobile && <LandscapeOverlay />}

      <Header />

      <div className={"innerApp"}>
        {isDisconnected && (
          <DisconnectOverlay
            isReconnect={isReconnect}
            handleClose={handleDisconnectOverlayClose}
          />
        )}
        
        {intakeCompleted ? (
          <MainController />
        ) : (
          <Intake
            handleIntakeSubmit={handleIntakeSubmit}
            disableJoin={disableJoin}
          />
        )}
      </div>
    </div>
  );
}

export default App;
